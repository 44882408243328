.xterm {
  cursor: text;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.xterm.focus, .xterm:focus {
  outline: none;
}

.xterm .xterm-helpers {
  z-index: 5;
  position: absolute;
  top: 0;
}

.xterm .xterm-helper-textarea {
  opacity: 0;
  z-index: -5;
  white-space: nowrap;
  resize: none;
  border: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: -9999em;
  overflow: hidden;
}

.xterm .composition-view {
  color: #fff;
  white-space: nowrap;
  z-index: 1;
  background: #000;
  display: none;
  position: absolute;
}

.xterm .composition-view.active {
  display: block;
}

.xterm .xterm-viewport {
  cursor: default;
  background-color: #000;
  position: absolute;
  inset: 0;
  overflow-y: scroll;
}

.xterm .xterm-screen {
  position: relative;
}

.xterm .xterm-screen canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.xterm .xterm-scroll-area {
  visibility: hidden;
}

.xterm-char-measure-element {
  visibility: hidden;
  line-height: normal;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -9999em;
}

.xterm.enable-mouse-events {
  cursor: default;
}

.xterm.xterm-cursor-pointer, .xterm .xterm-cursor-pointer {
  cursor: pointer;
}

.xterm.column-select.focus {
  cursor: crosshair;
}

.xterm .xterm-accessibility, .xterm .xterm-message {
  z-index: 10;
  color: #0000;
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.xterm .live-region {
  width: 1px;
  height: 1px;
  position: absolute;
  left: -9999px;
  overflow: hidden;
}

.xterm-dim {
  opacity: 1 !important;
}

.xterm-underline-1 {
  text-decoration: underline;
}

.xterm-underline-2 {
  -webkit-text-decoration: underline double;
  text-decoration: underline double;
}

.xterm-underline-3 {
  -webkit-text-decoration: underline wavy;
  text-decoration: underline wavy;
}

.xterm-underline-4 {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

.xterm-underline-5 {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
}

.xterm-overline {
  text-decoration: overline;
}

.xterm-overline.xterm-underline-1 {
  text-decoration: underline overline;
}

.xterm-overline.xterm-underline-2 {
  -webkit-text-decoration: overline double underline;
  text-decoration: overline double underline;
}

.xterm-overline.xterm-underline-3 {
  -webkit-text-decoration: overline wavy underline;
  text-decoration: overline wavy underline;
}

.xterm-overline.xterm-underline-4 {
  -webkit-text-decoration: overline dotted underline;
  text-decoration: overline dotted underline;
}

.xterm-overline.xterm-underline-5 {
  -webkit-text-decoration: overline dashed underline;
  text-decoration: overline dashed underline;
}

.xterm-strikethrough {
  text-decoration: line-through;
}

.xterm-screen .xterm-decoration-container .xterm-decoration {
  z-index: 6;
  position: absolute;
}

.xterm-screen .xterm-decoration-container .xterm-decoration.xterm-decoration-top-layer {
  z-index: 7;
}

.xterm-decoration-overview-ruler {
  z-index: 8;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
}

.xterm-decoration-top {
  z-index: 2;
  position: relative;
}

/*# sourceMappingURL=index.c437545b.css.map */
